class MaGridHelper {
  static MODE = {
    PAINT: 0,
    SHUFFLE: 1
  };

  constructor() {
    this.colorPalette = ['#F51928', '#85D58A', '#FF5522', '#77378B', '#F3DD65', '#7C89DF', '#F16B82', '#BBB532', '#2D60ED'];
    this.bgColor = '#F4E4CD';
    this.mode = MaGridHelper.MODE.PAINT;

    this.bodyEl = document.body;
    this.maContainerEl = document.querySelector('.ma-container');
    this.maGridEl = document.querySelector('.ma-grid');
    this.roseEl = document.querySelector('.ma-footer__rose-path');
    this.maSheetBoxEl = document.querySelector('.ma-sheet__box');
    this.aTagEls = this.bodyEl.querySelectorAll('a');
    this.logosEls = undefined;

    this.maxMobileWidth = 900;
    this.maxTabletWidth = 1300;
    this.logoPorpotion = Number(getComputedStyle(this.bodyEl).getPropertyValue('--logoPropotion'));

    this.numCol = undefined;
    this.supposedLogoHeight = undefined;
    this.numRows = undefined;
    this.totalLogos = undefined;
  }


  /** INIT **/
  init() {
    this.setGridVariables();

    this.maGridEl.innerHTML = this.addHtmlLogos();

    this.adjustHeights();

    this.addLogosInteraction();


    this.aTagEls.forEach(el => {
      const primaryColor = this.getRandomColor();
      const secondaryColor = this.getRandomColor(primaryColor);

      el.setAttribute('data-color-primary', primaryColor);
      el.setAttribute('data-color-secondary', secondaryColor);

      el.addEventListener('mouseenter', () => {
        let mColor;
        let aColor;

        mColor = el.getAttribute('data-color-primary');
        aColor = el.getAttribute('data-color-secondary');

        this.roseEl.style.fill = mColor;

        this.logosEls.forEach((logoEl, index) => {
          if(Math.floor(index / 10) % 2 == 0) {
            if(index % 2 != 0)
              logoEl.classList.add('ma-grid__logo--flip');
          } else {
            if(index % 2 == 0)
              logoEl.classList.add('ma-grid__logo--flip');
          }

          logoEl.querySelector('.ma-logo__m').style.fill = mColor;
          logoEl.querySelectorAll('.ma-logo__a').forEach(aEl => {
            aEl.style.fill = aColor;
          });
          logoEl.querySelectorAll('.ma-logo__bg').forEach(bgEl => {
            bgEl.style.fill = this.bgColor;
          });
        });
      });
    });

    /* Set CSS variable with scrollbar width */
    this.setScrollBarWidthCssVariable();

    /* Resize */
    let rafId = undefined;
    window.addEventListener('resize', setScrollBarWidthCssVariable => {
      rafId = this.debouncedRequestAnimationFrame(rafId, () => {
        this.setScrollBarWidthCssVariable();

        this.updateMaGridOnResize();
      });
    });

  }

  /** update Grid Variables **/
  setGridVariables() {
    this.numCol = this.isMobileLayout() ? 6 : 10;
    this.supposedLogoHeight = document.body.getBoundingClientRect().width / this.numCol * 1.2;
    this.numRows = this.isTabletLayout() ? (Math.ceil(document.body.getBoundingClientRect().height / this.supposedLogoHeight))+1 : (Math.ceil(document.body.getBoundingClientRect().height / this.supposedLogoHeight));
    this.totalLogos = this.numCol * this.numRows;
  }

  /** is mobile layout **/
  isMobileLayout() {
    return window.innerWidth > this.maxMobileWidth ? false : true;
  }

  /** is tablet layout **/
  isTabletLayout() {
    return window.innerWidth > this.maxTabletWidth ? false : true;
  }

  /** Adjust/force heights **/
  adjustHeights() {
    const logoHeight = this.maContainerEl.querySelectorAll('.ma-grid__logo')[0].getBoundingClientRect().height;
    const discountRowsOfFooter = this.isTabletLayout() ? 7 : 6;

    this.maContainerEl.style.height = this.maGridEl.getBoundingClientRect().height + 'px';
    this.maSheetBoxEl.style.height = (logoHeight * (this.numRows - discountRowsOfFooter)) + 'px';
  }

  /** Revert adjusted/forced heights **/
  revertAdjustedHeights() {
    this.maContainerEl.style.height = 'auto';
    this.maSheetBoxEl.style.height = 'auto';
  }

  addHtmlLogos(lengthLogos) {
    const lengthLogosToAdd = lengthLogos || this.totalLogos;
    let htmlLogos = '';

    for(let i = 0; i < lengthLogosToAdd; i++) {
      const randomColor1 = this.getRandomColor();
      const randomColor2 = this.getRandomColor(randomColor1);

      const logoHtml =
        `<div class="ma-grid__logo">
          <svg class="ma-logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170 203.81">
             <g>
               <polygon class="ma-logo__part ma-logo__bg" style="fill:${this.bgColor}" points="90.02 84.44 90.02 63.25 79.98 63.25 79.98 84.44 70.8 84.44 70.8 98.77 70.8 105.3 70.8 119.54 79.98 119.54 79.98 140.82 90.02 140.82 90.02 119.54 99.2 119.54 99.2 105.3 99.2 98.77 99.2 84.44 90.02 84.44" />
               <polygon class="ma-logo__part ma-logo__bg" style="fill:${this.bgColor}" points="59.98 78.26 60.07 78.26 60.07 57.67 69 57.67 69 43.42 98.52 43.42 98.52 57.67 110.02 57.67 110.02 78.26 118.08 78.26 118.08 0 52.26 0 52.26 78.26 59.98 78.26" />
               <polygon class="ma-logo__part ma-logo__bg" style="fill:${this.bgColor}" points="41.02 84.7 32.52 84.7 32.52 25.49 23.17 25.49 23.17 178.58 32.52 178.58 32.52 119.37 41.02 119.37 41.02 105.3 41.02 98.77 41.02 84.7" />
               <polygon class="ma-logo__part ma-logo__bg" style="fill:${this.bgColor}" points="137.48 84.7 128.98 84.7 128.98 98.77 128.98 105.3 128.98 119.37 137.48 119.37 137.48 178.58 146.92 178.58 146.92 25.49 137.48 25.49 137.48 84.7" />
               <polygon class="ma-logo__part ma-logo__bg" style="fill:${this.bgColor}" points="110.02 125.81 110.02 146.4 98.52 146.4 98.52 160.65 69 160.65 69 146.4 60.07 146.4 60.07 125.81 59.98 125.81 52.26 125.81 52.26 203.81 118.08 203.81 118.08 125.81 110.02 125.81" />
               <polygon class="ma-logo__part ma-logo__a" style="fill:${randomColor2}" points="137.48 178.58 137.48 119.37 128.98 119.37 128.98 105.3 118.08 105.3 118.08 125.81 118.08 203.81 170 203.81 170 178.58 146.92 178.58 137.48 178.58" />
               <polygon class="ma-logo__part ma-logo__a" style="fill:${randomColor2}" points="70.8 98.77 70.8 84.44 79.98 84.44 79.98 63.25 90.02 63.25 90.02 84.44 99.2 84.44 99.2 98.77 110.02 98.77 110.02 78.26 110.02 57.67 98.52 57.67 98.52 43.42 69 43.42 69 57.67 60.07 57.67 60.07 78.26 59.98 78.26 59.98 98.77 70.8 98.77" />
               <polygon class="ma-logo__part ma-logo__a" style="fill:${randomColor2}" points="52.26 105.3 41.02 105.3 41.02 119.37 32.52 119.37 32.52 178.58 23.17 178.58 0 178.5 0 203.81 52.26 203.81 52.26 125.81 52.26 105.3" />
               <polygon class="ma-logo__part ma-logo__m" style="fill:${randomColor1}" points="146.92 0 118.08 0 118.08 78.26 110.02 78.26 110.02 98.77 99.2 98.77 99.2 105.3 99.2 119.54 90.02 119.54 90.02 140.82 79.98 140.82 79.98 119.54 70.8 119.54 70.8 105.3 70.8 98.77 59.98 98.77 59.98 78.26 52.26 78.26 52.26 0 23.17 0 0 0 0 178.5 23.17 178.58 23.17 25.49 32.52 25.49 32.52 84.7 41.02 84.7 41.02 98.77 41.02 105.3 52.26 105.3 52.26 125.81 59.98 125.81 60.07 125.81 60.07 146.4 69 146.4 69 160.65 98.52 160.65 98.52 146.4 110.02 146.4 110.02 125.81 118.08 125.81 118.08 105.3 128.98 105.3 128.98 98.77 128.98 84.7 137.48 84.7 137.48 25.49 146.92 25.49 146.92 178.58 170 178.58 170 0 146.92 0" />
             </g>
           </svg>
          </div>`;
      htmlLogos += logoHtml;
    }

    return htmlLogos;
  }

  addLogosInteraction() {
    this.logosEls = this.maGridEl.querySelectorAll('.ma-grid__logo');
    this.logosPartsEls = this.maGridEl.querySelectorAll('.ma-logo__part');

    this.logosEls.forEach(el => {
      el.addEventListener('mousedown', e => {
        e.preventDefault();
      });
    });

    this.logosPartsEls.forEach(partEl => {
      partEl.addEventListener('mouseenter', () => {
        this.addRandomColor(partEl);
      });

      partEl.addEventListener('click', () => {
        this.logosEls.forEach(el => {
          el.classList.remove('ma-grid__logo--flip');
          this.addMaRandomColor(el);
          this.addRandomColor(this.roseEl);
        });
      });
    });
  }

  /** Update MA grid on resize **/
  updateMaGridOnResize() {
    const lastNumRows = this.numRows;
    const lastTotalLogos = this.totalLogos;

    this.revertAdjustedHeights();

    this.setGridVariables();

    if(lastNumRows != this.numRows) {
      const newTotalLogos = this.numCol * this.numRows;
      const diffLogos = newTotalLogos - lastTotalLogos;

      if(diffLogos > 0) {
        this.maGridEl.innerHTML += this.addHtmlLogos(Math.abs(diffLogos));

        this.addLogosInteraction();
      } else {
        for(let i = this.maGridEl.querySelectorAll('.ma-grid__logo').length; i > newTotalLogos; i--) {
          this.maGridEl.querySelectorAll('.ma-grid__logo')[i -1].remove();
        }
      }
    }

    this.adjustHeights();
  }

  /** Add random colors to MA logo **/
  addMaRandomColor(el) {
    const colorM = this.getRandomColor();
    const colorA = this.getRandomColor(colorM);

    el.querySelector('.ma-logo__m').style.fill = colorM;
    el.querySelectorAll('.ma-logo__a').forEach(aEl => {
      aEl.style.fill = colorA;
    });
    el.querySelectorAll('.ma-logo__bg').forEach(bgEl => {
      bgEl.style.fill = this.bgColor;
    });
  }

  /** Add random color **/
  addRandomColor(el) {
    el.style.fill = this.getRandomColor();
  }

  /** Get colors to MA logo **/
  getMaColors(el) {
    const colorM = el.querySelector('.ma-logo__m').style.fill;
    const colorA = el.querySelector('.ma-logo__a').style.fill;

    return {
      m : colorM,
      a : colorA
    };
  }

  /** Get Random Integer **/
  getRandomColor(skipColor) {
    let color = this.colorPalette[this.getRndInteger(0, this.colorPalette.length - 1)];

    if(skipColor) {
      while (color == skipColor) {
        color = this.colorPalette[this.getRndInteger(0, this.colorPalette.length - 1)];
      }
    }

    return color;
  }

  /** Get Random Integer **/
  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }

  /** Debounced Request Animation Frame **/
  debouncedRequestAnimationFrame(timeoutId, callback) {
    if (timeoutId)
      window.cancelAnimationFrame(timeoutId);

    return timeoutId = window.requestAnimationFrame(callback);
  }

  /*
   * DEBOUNCE

    var example = debounce(function() {
      ...
    }, 500);

   */
  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  /* Set CSS variable with scrollbar width */
  setScrollBarWidthCssVariable() {
    document.body.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + 'px');
  }
}

export default MaGridHelper;
