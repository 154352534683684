import 'html5-boilerplate/dist/js/vendor/modernizr-3.11.2.min.js';
import 'html5-boilerplate/dist/js/plugins.js';
import 'cssuseragent';
import MaGridHelper from './modules/ma-grid-helper.js';

/*****************************************************************************/
/***************************  GENERAL FUNCTIONS  *****************************/
function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function getRnd(min, max) {
  return Math.random() * (max - min) + min;
}

function debouncedRequestAnimationFrame(timeoutId, callback) {
  if (timeoutId)
    window.cancelAnimationFrame(timeoutId);

  return timeoutId = window.requestAnimationFrame(callback);
}

/*
 * DEBOUNCE

  var example = debounce(function() {
    ...
  }, 500);

 */
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};



/*****************************************************************************/
/********************************  FUNCTIONS  ********************************/

/* Set CSS variable of Hover Color */
const colorPalette = ['#F51928', '#85D58A', '#FF5522', '#77378B', '#F3DD65', '#7C89DF', '#F16B82', '#BBB532', '#2D60ED'];

function changeHoverColor(color) {

  if(!color)
    color = colorPalette[getRndInteger(0, colorPalette.length - 1)];

  document.body.style.setProperty('--hoverColor', color);
}

document.querySelectorAll('a').forEach(el => {
  el.addEventListener('mouseenter', event => {
    const primaryColor = el.getAttribute('data-color-primary');

    if(primaryColor)
      changeHoverColor(primaryColor);
    else
      changeHoverColor();
  });
});



/*****************************************************************************/
/******************************  DOCUMENT READY  *****************************/
let maGrid;
document.addEventListener('DOMContentLoaded', function() {
  changeHoverColor();

  maGrid = new MaGridHelper;
  maGrid.setScrollBarWidthCssVariable();
});

window.onload = function(){
  maGrid.init();
};

